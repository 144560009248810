import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout.js"
import Seo from "../components/seo"
import Latest from "../components/latest"
import Rightcol from "../components/rightcol"

const Popular = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Latest Manga" />
      <div className="flex-grow lg:flex border-t-4 border-blue-500 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto ">
        <div className="min-w-0 flex-1 bg-white xl:flex">
         
          <div className="bg-transparent lg:min-w-0 lg:flex-1">
            <div className="border-b border-t border-gray-200 p-2 xl:border-t-0">
              <div className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z" />
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z" />
                </svg>
                <h1 className="text-lg font-medium pl-2">POPULAR MANGA</h1>
              </div>
            </div>

            <div className="pl-2 pr-2 pt-4 pb-4   xl:border-t-0  xl:pt-6" isHomePage>
              <div className="flex items-center">
              <Latest/>
              </div>
            </div>
          </div>
        </div>
        <Rightcol/>
      </div>
    </Layout>
  )
}

export default Popular

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
